var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.isShown),callback:function ($$v) {_vm.isShown=$$v},expression:"isShown"}},[_c('v-card',{attrs:{"data-cy":"activateAutoDeletionDialog"}},[_c('v-card-title',[_vm._v("\n      "+_vm._s(_vm.$t('business.auto_deletion.disabled.dialog.title'))+"\n    ")]),_vm._v(" "),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$sanitize(
            _vm.isLegacy
              ? _vm.$t('business.auto_deletion.disabled.dialog.legacy_subtitle')
              : _vm.$t('business.auto_deletion.disabled.dialog.subtitle')
          )
        )}})]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('v-checkbox',{staticClass:"py-1 mt-0",attrs:{"hide-details":"","on-icon":"$vuetify.icons.checkbox_active","off-icon":"$vuetify.icons.checkbox_inactive","label":_vm.$t('business.auto_deletion.disabled.dialog.checkbox_label'),"data-cy":"confirmChangesCheckbox"},model:{value:(_vm.isConsentGiven),callback:function ($$v) {_vm.isConsentGiven=$$v},expression:"isConsentGiven"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"info","outlined":""},on:{"click":function($event){_vm.isShown = false}}},[_vm._v("\n        "+_vm._s(_vm.$t('business.auto_deletion.disabled.dialog.no_thanks'))+"\n      ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"x-large":"","color":"info","disabled":!_vm.isConsentGiven,"data-cy":"activateAutoDeletionButton"},on:{"click":_vm.onActivate}},[_vm._v("\n        "+_vm._s(_vm.$t('business.auto_deletion.disabled.dialog.activate'))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }