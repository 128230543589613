<template>
  <v-dialog v-model="isShown" max-width="600">
    <v-card data-cy="activateAutoDeletionDialog">
      <v-card-title>
        {{ $t('business.auto_deletion.disabled.dialog.title') }}
      </v-card-title>
      <v-card-text>
        <p
          v-html="
            $sanitize(
              isLegacy
                ? $t('business.auto_deletion.disabled.dialog.legacy_subtitle')
                : $t('business.auto_deletion.disabled.dialog.subtitle')
            )
          "
        />
      </v-card-text>
      <v-divider />
      <v-card-text>
        <v-checkbox
          v-model="isConsentGiven"
          class="py-1 mt-0"
          hide-details
          on-icon="$vuetify.icons.checkbox_active"
          off-icon="$vuetify.icons.checkbox_inactive"
          :label="$t('business.auto_deletion.disabled.dialog.checkbox_label')"
          data-cy="confirmChangesCheckbox"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn x-large color="info" outlined @click="isShown = false">
          {{ $t('business.auto_deletion.disabled.dialog.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn
          x-large
          color="info"
          :disabled="!isConsentGiven"
          data-cy="activateAutoDeletionButton"
          @click="onActivate"
        >
          {{ $t('business.auto_deletion.disabled.dialog.activate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isLegacy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isConsentGiven: false,
    }
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean): void {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    onActivate() {
      if (this.isConsentGiven) {
        this.$emit('activate')
        this.isShown = false
      }
    },
  },
})
</script>
